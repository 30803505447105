<template>
<div class="container">
  <div class="top-box">
    <img class="background" src="/img/main/background.png" alt="">
    <div class="top">{{ name }}</div>
    <div class="bottom">
      <div class="phone" @click="toLogout">{{ username }} ></div>
      <template v-if="role == 4">
        <div class="guide" @click="toHelp">用户操作说明</div>
        <img class="icon" src="/img/main/info.png">
      </template>
    </div>
    <div></div>
  </div>
  <div class="content-box">
	<div class="btn_item create_btn" @click="toCreate">
		<img src="/img/main/icon_create.png" alt="">
		<div class="">
			<div class="btn_item_tit">创建评估</div>
			<div>Create Assessment</div>
		</div>
	</div>
	<div class="btn_item org_btn" @ @click="showList(1)">
		<img src="/img/main/icon_org.png" alt="">
		<div class="">
			<div class="btn_item_tit">机构自测</div>
			<div>Organization Self-Assessment</div>
		</div>
	</div>
	<div class="btn_item city_btn"  @click="showList(2)">
		<img src="/img/main/icon_city.png" alt="">
		<div class="">
			<div class="btn_item_tit">区旗评估专家</div>
			<div>MHC Assessment Exper</div>
		</div>
	</div>
    <div v-if="role == 2"  class="btn_item nation_btn"  @click="showList(3)">
    	<img src="/img/main/icon_nation.png" alt="">
    	<div class="">
    		<div class="btn_item_tit">市级评估专家</div>
    		<div>Organization Self-Assessment</div>
    	</div>
    </div>
  </div>
</div>
<!-- </div> -->
</template>

<script>
export default {
  name: "Main",
  data() {
    const that = this;
    return {
      tabLeft: 'common-tab',
      showSupervisor: false, // 展示督导页面
      showCommon: false, // 展示机构页面
      role: '',
      name: '',
      username: '',
    };
  },
  computed: {},
  watch: {

  },
  components: {},
  beforeRouteUpdate(to, from, next) {},
  mounted() {
    this.name = localStorage.getItem('name')
    this.username = localStorage.getItem('username')
    this.role = localStorage.getItem('role')
  },
  actived() {

  },
  methods: {
    toCreate() {
      this.$router.push('/create')
    },
    toHelp() {
      this.$router.push('/help')
    },
    toLogout() {
      this.$router.push('/user/logout')
    },
    showList(type) {
      this.$router.push({
        path: '/main',
        query: {
          type
        }
      })
    },
  },
};
</script>

<style lang="scss">
.container {
  height: 100%;

.btn_item{   width: 345px;
      height: 107px;
      margin: 10px auto;
	  display: flex;
	  justify-content: start;
	  align-items: center;
	  img{ width:60px; height: 60px;margin:0 10px 0 20px}
	  div{ text-align: left; color:#fff;
	  font-size: 12px;;
	  .btn_item_tit{ font-size: 18px; font-weight:600; margin-bottom:10px;}
	  }
	  }
.create_btn{  background-image: url('/img/main/create-btn.png');
     background-size: 100% 100%;
}
.org_btn{  background-image: url('/img/main/org-btn.png');
     background-size: 100% 100%;
}
.city_btn{  background-image: url('/img/main/city-btn.png');
     background-size: 100% 100%;
}
.nation_btn{  background-image: url('/img/main/nation-btn.png');
     background-size: 100% 100%;
}
  .top-box {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 125px;
    // background-image: url('/img/main/background.png');
    // background-size: 100% 125px;

    .background {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 125px;
    }

    .top {
      margin: 25px 0 0 15px;
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 27px;
    }

    .bottom {
      z-index: 99;
      margin: 4px 12px 0 15px;
      display: flex;
      align-items: center;

      .phone {
        margin-right: auto;
        font-size: 14px;
        font-weight: 400;
        color: #FFF;
        line-height: 20px;
      }

      .guide {
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background-clip: text;
      }

      .icon {
        margin-left: 3px;
        width: 13px;
        height: 13px;
      }
    }
  }

  .content-box {
    position: absolute;
    top: 100px;
    z-index: 99;
    height: calc(100% - 100px);
    width: 100%;
    overflow-y: scroll;
    background: #F7F8FA;
    border-radius: 10px 10px 0px 0px;

    .content-btn {
      width: 345px;
      height: 107px;
      margin: 10px 15px 0;
    }

    .content-btn:first {
      margin-top: 20px;
    }

    // .swiper-container {
    //     overflow-y: scroll;
    // }

    .inner {
      position: relative;
      overflow-y: scroll;
      height: 100%;
      padding: 15px;
      padding-bottom: 80px;

      .inner-box {
        .single {
          position: relative;
          padding: 15px;
          margin-top: 10px;
          width: auto;
          background: #FFFFFF;
          box-shadow: 0px 1px 5px 0px rgba(245, 245, 245, 1);
          border-radius: 6px;

          .single-type {
            width: 55px;
            height: 26px;
            position: absolute;
            top: 10px;
            right: -4px;
          }

          .title {
            text-align: left;
            font-size: 17px;
            font-weight: 500;
            color: #333333;
            line-height: 17px;
          }

          .time {
            text-align: left;
            margin-top: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            line-height: 14px;
          }

          .score {
            display: flex;
            text-align: left;
            align-items: center;
            justify-content: space-between;
            margin-top: 19px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 14px;

            .score-box {
              display: flex;
              flex-direction: column;

              .score-box__inner1 {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }

              .score-box__inner2 {
                min-height: 14px;
                margin-top: 14px;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                line-height: 14px;
              }
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .nodata-box {
        margin-top: 200px;

        img {
          width: 196px;
          height: 119px;
        }

        .nodata-desc {
          font-size: 14px;
          font-weight: 400;
          color: #888888;
          line-height: 21px;
        }
      }
    }
  }

  .btn-box {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 73px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .login-btn {
      width: 323px;
      font-size: 19px;
      color: #ffffff;
    }
  }

  .model-box {
    z-index: 2001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;

    .select-box {
      width: 240px;
      height: 133px;
      background: #FFFFFF;
      border-radius: 6px;
      display: inline-block;
      vertical-align: middle;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto;

      .select-box__top {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #222222;
        height: 88px;
        border-bottom: 1px solid #EEEEEE;
      }

      .select-box__bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        .select-box__bottom--left {
          width: calc((100% - 1px)/2);
          font-size: 17px;
          font-weight: 400;
          color: #666666;
          line-height: 45px;
        }

        .select-box__bottom--mid {
          width: 1px;
          height: 45px;
          background: #EEEEEE;
        }

        .select-box__bottom--right {
          width: calc((100% - 1px)/2);
          font-size: 17px;
          font-weight: 400;
          color: #0083F5;
          line-height: 45px;
        }
      }
    }
  }

  .model {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
  }
}
</style>

<style lang="scss">
.common-tab {
  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }

  ::v-deep .el-form-item__content {
    display: flex;
  }
}

.supervisor-tab {
  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }

  ::v-deep .el-form-item__content {
    display: flex;
  }
}
</style>
